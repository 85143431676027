

import React, { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa6";
import { CiMail } from "react-icons/ci";
import { useTranslation } from "react-i18next";
import { contact, contactBg } from "../../../assets/images";


const Contact = () => {

  const { t, i18n } = useTranslation(); 
  return (
    <>
   <div className="mt-[100px]">
     <div className='mb-[40px] text-center'>
     <p className='text-[#6F9B0B] text-[32px] font-medium'>Əlaqə</p>
       <p className='text-xl italic text-[#BCBCBC]'>Təkliflərinizi bizə söyləyə bilərsiniz</p>
     </div>
   </div>
      <div>
    <div className='mx-auto mt-[100px] mb-[160px] max-w-[1260px]'>
 <div className='flex mb-[40px] justify-between'>
<div className='w-[55%]'>
<form class="space-y-8">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6"> 
      <div>
        <label for="company" class="block text-base font-medium text-[black]">Ad və ya Şirkətin adı</label>
        <input type="text" id="company" class="bg-neutral-100 mt-2 block w-full pl-3 pr-3 h-10 border border-[#dddddd] rounded-xl shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm " ></input>
      </div>
      <div>
        <label for="email" class="block text-base font-medium text-[black]">E-mail</label>
        <input type="email" id="email" class="bg-neutral-100 mt-2 block w-full pl-3 pr-3 h-10 border border-[#dddddd] rounded-xl shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm " ></input>
      </div>
    </div>
    
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6"> 
      <div>
        <label for="quantity" class="block text-base font-medium text-[black]">Tələb etdiyiniz miqdar</label>
        <input type="number" id="quantity" class="bg-neutral-100 pl-3 pr-3 mt-2 block w-full h-10 border border-[#dddddd] rounded-xl shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm "></input>
      </div>
      <div>
        <label for="service" class="block text-base font-medium text-[black]">Xidmət sahəsi</label>
        <select id="service" class="bg-neutral-100 text-[#bcbcbc] pl-3 pr-3 mt-2 block w-full h-10 border border-[#dddddd] rounded-xl shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm ">
          <option>Seçin</option>
          <option>Option 1</option>
          <option>Option 2</option>
        </select>
      </div>
    </div>

    <div>
      <label for="message" class="block text-base font-medium text-[black]">Əlavə mesajınız</label>
      <textarea id="message" rows="4" class="bg-neutral-100 h-36 mt-2 pl-3 pr-3 pt-3 block w-full border border-[#dddddd] rounded-xl shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm" ></textarea>
    </div>

    <div class="flex justify-start">
      <button type="submit" class="w-[148px] h-10 p-2.5 bg-[#6f9b0b] rounded-xl justify-center items-center gap-2.5 inline-flex text-[white]">Göndər</button>
    </div>
  </form>
 </div>
 <div className='w-[37%] flex flex-col justify-between'>
  <img src={contact}></img>
</div> 
 </div>
 <div className=' h-[445px]'>
 <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12156.67646606886!2d49.8410604!3d40.3829443!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d640daa4cb5%3A0x8f57e7e504a1dd71!2sAF%20City!5e0!3m2!1saz!2saz!4v1726156675777!5m2!1saz!2saz" width="100%" height="445" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
 </div>
    </div>
  </div>
    </>

  );
};

export default Contact;