import React from 'react'
import { blog1, blog2, blog3, blogBg, huwasan, leaf2, whyus } from '../../assets/images'


const BlogDetail = () => {

  const products = [
    {
      id: 1,
      title: 'Huwasanın Tibb sahəsində İstifadəsi',
      content: 'Laboratoriyaların, cərrahi materialların və alətlərin dezinfeksiyası. (alətlərin yuyulması);...',
      image: blog1, // Replace with the actual image path
      path: '/sofa'
    },
    {
      id: 2,
      title: 'Huwasanın Kənd Təsərrüfatı sektor...',
      content: 'Bitki suvarma suyunda patogen mikroorqanizmlərə və yosunlara qarşı mübarizədə;',
      image: blog2, // Replace with the actual image path
       path: '/top'
    },
    {
      id: 3,
      title: 'Huwasanın Heyvandarlıq və Quşçul...',
      content: 'Heyvan sığınacaqlarının, qəfəslərin və toyuq hinilərinin dezinfeksiyasında.; Heyvanları daşıy...',
      image: blog3, // Replace with the actual image path
      path:'/komfort',
      
    },
    {
      id: 1,
      title: 'Huwasanın Tibb sahəsində İstifadəsi',
      content: 'Laboratoriyaların, cərrahi materialların və alətlərin dezinfeksiyası. (alətlərin yuyulması);...',
      image: blog1, // Replace with the actual image path
      path: '/sofa'
    },
    {
      id: 2,
      title: 'Huwasanın Kənd Təsərrüfatı sektor...',
      content: 'Bitki suvarma suyunda patogen mikroorqanizmlərə və yosunlara qarşı mübarizədə;',
      image: blog2, // Replace with the actual image path
       path: '/top'
    },
    {
      id: 3,
      title: 'Huwasanın Heyvandarlıq və Quşçul...',
      content: 'Heyvan sığınacaqlarının, qəfəslərin və toyuq hinilərinin dezinfeksiyasında.; Heyvanları daşıy...',
      image: blog3, // Replace with the actual image path
      path:'/komfort',
      
    },
    {
      id: 1,
      title: 'Huwasanın Tibb sahəsində İstifadəsi',
      content: 'Laboratoriyaların, cərrahi materialların və alətlərin dezinfeksiyası. (alətlərin yuyulması);...',
      image: blog1, // Replace with the actual image path
      path: '/sofa'
    },
    {
      id: 2,
      title: 'Huwasanın Kənd Təsərrüfatı sektor...',
      content: 'Bitki suvarma suyunda patogen mikroorqanizmlərə və yosunlara qarşı mübarizədə;',
      image: blog2, // Replace with the actual image path
       path: '/top'
    },
    {
      id: 3,
      title: 'Huwasanın Heyvandarlıq və Quşçul...',
      content: 'Heyvan sığınacaqlarının, qəfəslərin və toyuq hinilərinin dezinfeksiyasında.; Heyvanları daşıy...',
      image: blog3, // Replace with the actual image path
      path:'/komfort',
      
    },
  ];
  return (
   <>
        <div style={{height:'230px', backgroundImage: `url(${blogBg})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'flex', justifyContent:'center', alignItems:'end' }}>
     <div className='mb-[44px] text-center'>
       <p className='text-[#6F9B0B] text-[40px] font-medium'>Bloq</p>
     </div>
   </div>
       <div className='max-w-[1260px] mx-auto'>
   <div className='mt-[100px] mb-[100px] flex justify-between'>
  <div className='w-[30%]'>
    <img src={huwasan}></img>
  </div>
  <div className='w-[66%]'>
    <p className='text-[28px] font-medium mb-5'>
    Huwa-San yeni nəsil ekoloji dezinfeksiyaedici 
    </p>
    <p className='text-base text-[#a7a7a7]'>
    Huwa-San yeni nəsil ekoloji dezinfeksiyaedici və bitki bəsləyicidir. Xüsusi formulası kolloid gümüş hissəcikləri ilə stabilləşdirilmiş hidrogen peroksiddir. Koloidal gümüş və hidrogen peroksid arasında sinergetik qarşılıqlı əlaqə ilə Huwa-San çox təsirlidir. Aşağı dozalarda güclü təsir göstərir. Huwasan effektiv, ekoloji cəhətdən təmiz və qənaətcil dezinfeksiyaedicidir. Huwasan güclü, təsirli, ekoloji cəhətdən təmiz dezinfeksiyaedici olduğundan, müəssisələrdə bir çox sahədə uğurla tətbiq olunur.
    </p>
  </div>
    </div>
    </div>
     
    </>
  )
}

export default BlogDetail