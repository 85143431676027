import React from 'react'
import { blogLeft, blogRightBottom, blogRightTop, productBg, servicesBg, whyus } from '../../assets/images';

const Services = () => {
  return (
   <>
   <div style={{height:'310px', backgroundImage: `url(${servicesBg})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'flex', justifyContent:'center', alignItems:'end' }}>
     <div className='mb-[40px] text-center'>
       <p className='text-xl italic text-[#BCBCBC]'>Xidmətlər</p>
       <p className='text-[#6F9B0B] text-[32px] font-medium'>İstifadə sahəsi</p>
     </div>
   </div>
    <div>
    <div className=' h-[416px] mx-auto flex justify-between mt-[100px] mb-[160px] max-w-[1260px]'>
    <div>
   <img className='h-[348px] w-[524px]' src={blogLeft}></img>
    </div>
    <div className='flex justify-center items-center w-[696px] text-lg'>
      <p>Parniklərdə, ştilxanalarda, quşçuluq və heyvandarlıqda, açıq və qapalı hovuzlarda, xəstəxanalarda, hamamlarda, suvarma sistemlərində, bütün növ məkanların dezinfeksiyasında bizim məhsulumuzu istifadə edə bilərsiniz. . Hər damlasında möcüzə var. Buna zəmanət veririk.</p>
    </div>
    </div>
  </div></>
  )
}

export default Services