import React from 'react'
import { certificate1, certificate2, certificate3, certificate4, certificate5, certificate6, huwasan, productBg, virus1, virus2, virus3, virus4, virus5, whyus } from '../../assets/images';

const Products = () => {
  return (
   <>
   <div style={{height:'230px', backgroundImage: `url(${productBg})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'flex', justifyContent:'center', alignItems:'end' }}>
     <div className='mb-[44px] text-center'>
       <p className='text-[#6F9B0B] text-[40px] font-medium'>Məhsullarımız</p>
     </div>
   </div>
    <div> 
    <div className='max-w-[1260px] mx-auto'>
    <div className='mt-[100px] flex justify-between'>
  <div className='w-[30%]'>
    <img src={huwasan}></img>
  </div>
  <div className='w-[66%]'>
    <p className='text-base'>
    Huwa-San yeni nəsil ekoloji dezinfeksiyaedici və bitki bəsləyicidir. Xüsusi formulası kolloid gümüş hissəcikləri ilə stabilləşdirilmiş hidrogen peroksiddir. Koloidal gümüş və hidrogen peroksid arasında sinergetik qarşılıqlı əlaqə ilə Huwa-San çox təsirlidir. Aşağı dozalarda güclü təsir göstərir. Huwasan effektiv, ekoloji cəhətdən təmiz və qənaətcil dezinfeksiyaedicidir. Huwasan güclü, təsirli, ekoloji cəhətdən təmiz dezinfeksiyaedici olduğundan, müəssisələrdə bir çox sahədə uğurla tətbiq olunur.
    </p>
  </div>
    </div>

    <div className='mt-[124px]'>
      <p className='text-[#6F9B0B] text-[32px] font-medium text-center mb-[30px]'>Sertifikatlarımız</p>
      <div className='flex justify-between gap-10'>
   <img className='h-[68px] w-52'  src={certificate1}></img>
   <img className='w-[118px] h-[87px]' src={certificate2}></img>
   <img className='h-[101px] w-[168px]'  src={certificate3}></img>
   <img className='h-[68px] w-[196px]' src={certificate4}></img>
   <img className='w-[108px] h-[126px]' src={certificate5}></img>
   <img className='w-28 h-[138px]' src={certificate6}></img>
      </div>
    </div>
    </div>
    <div className='mt-[100px] mb-[120px] bg-[#F1F1F1] pb-10'>
    <p className='text-[#6F9B0B] text-[32px] font-medium text-center mb-[30px] pt-[50px]'>Üstünlüklərimiz</p>
    <div className='max-w-[1260px] mx-auto'>

    <div>
  <div className='grid grid-cols-4 gap-5'>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>01</p>
<p className='text-black text-sm font-normal'>Huwasan TR 50 bir məhsulla döşəmə, səth, alət və havanın dezinfeksiyasını təmin edir.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>02</p>
<p className='text-black text-sm font-normal'>Huwasan güclü və ekoloji dezinfeksiyaedicidir. İstifadəçi dostudur.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>03</p>
<p className='text-black text-sm font-normal'>Bitkilərin böyüməsi üçün lazım olan oksigeni bol miqdarda birbaşa kökdən verir.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>04</p>
<p className='text-black text-sm font-normal'>Bitkinin lazımi qida maddələrini ala bilməsi üçün üzvi maddələri parçalayır.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>05</p>
<p className='text-black text-sm font-normal'>Huwa-San ştilləri və kökləri patogenlərdən qoruyur.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>06</p>
<p className='text-black text-sm font-normal'>Aktiv oksigenə əsaslanır və tərkibində kolloid gümüşü ilə stabilləşdirilmiş Hidrogen Peroksid var.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>07</p>
<p className='text-black text-sm font-normal'>Yüksək səviyyədə dezinfeksiyanı təmin edir.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>08</p>
<p className='text-black text-sm font-normal'>Geniş fəaliyyət spektrinə malikdir.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>09</p>
<p className='text-black text-sm font-normal'>Bakterisid, virususidal, sporosid, funqisid və amibisid təsir göstərir.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>10</p>
<p className='text-black text-sm font-normal'>Stabildir və depo effektinə malikdir.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>11</p>
<p className='text-black text-sm font-normal'>95 dərəcə istiliyə qədər qərarlıdır.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>12</p>
<p className='text-black text-sm font-normal'>Rəngsiz və qoxusuzdur. Heç bir narahatedici rəng, qoxu və ya dad yaratmır.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>13</p>
<p className='text-black text-sm font-normal'>99,9% bioloji parçalana bilir.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>14</p>
<p className='text-black text-sm font-normal'>Reaksiyadan sonra suya və oksigenə çevrilir. Zərərli heç bir qalıq buraxmır.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>15</p>
<p className='text-black text-sm font-normal'>Zəhərli deyil.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>16</p>
<p className='text-black text-sm font-normal'>Xlor və spirtdən azaddır.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>17</p>
<p className='text-black text-sm font-normal'>İstifadədən sonra yaxalamaq lazım deyil.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>18</p>
<p className='text-black text-sm font-normal'>Xlorun əksinə kanserogen və ya mutagen deyildir.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>19</p>
<p className='text-black text-sm font-normal'>Ətrafda heç bir qalıq qoymur.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>20</p>
<p className='text-black text-sm font-normal'>Aşağı dozalarda yüksək səviyyədə təsir göstərir.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>21</p>
<p className='text-black text-sm font-normal'>Müvafiq dozalarda istifadə edən personala və materiallara heç bir mənfi təsiri yoxdur.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>22</p>
<p className='text-black text-sm font-normal'>Tətbiq dozalarında aşındırıcı deyil.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>23</p>
<p className='text-black text-sm font-normal'>Təbiət və insanlar üçün faydalı olan ekoloji dezinfeksiyaedicidir.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>24</p>
<p className='text-black text-sm font-normal'>Güclü, effektiv və təhlükəsiz dezinfeksiyaedicidir.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>25</p>
<p className='text-black text-sm font-normal'>Müxtəlif məhsullar üçün əlavə xərclərinizi aradan qaldırır.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>26</p>
<p className='text-black text-sm font-normal'>Konsentratlaşdırılmış dezinfeksiyaedicidir. Ekonomikdir.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>27</p>
<p className='text-black text-sm font-normal'>Test çubuqları (ölçüm çubuqları) ilə dərhal ölçülə bilər.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>28</p>
<p className='text-black text-sm font-normal'>Quş qripinə (Avian influenza) qarşı təsirlidir.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>29</p>
<p className='text-black text-sm font-normal'>ISO 9001:2000 və ISO 14001:2004 keyfiyyət idarəetmə sistemi şərtləri altında Belçikada istehsal olunur.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>30</p>
<p className='text-black text-sm font-normal'>35 ölkədə lisenziyaya malikdir.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>31</p>
<p className='text-black text-sm font-normal'>Huwa-San və Huwa-San texnologiyaları beynəlxalq səviyyədə etibarlı keyfiyyət sistemləri sertifikatına malikdir.</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>32</p>
<p className='text-black text-sm font-normal'>Huwa-San DEFRA sertifikatlarına sahibdir. (DEFRA- Ətraf Mühit, Qida və Kənd İşləri Departamenti, Böyük Britaniya Ətraf Mühit,Ərzaq və Kənd Təsərrüfatı Nazirliyi)</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>33</p>
<p className='text-black text-sm font-normal'>CE sertifikatına malikdir. (CE sertifikatı Avropa İttifaqı və Avropa İqtisadi Bölgəsində bazara çıxarılan məhsullar üçün məcburi uyğunluq sertifikatıdır (nişanıdır). CE (sertifikatı) işarəsi məhsulların insan həyatına və əmlakına, bitki və heyvan varlığına və ətraf mühitə zərər verməyəcəyini göstərir.)</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>34</p>
<p className='text-black text-sm font-normal'>Huwa-San NSF sertifikatına malikdir. (NSF Sertifikatı qida və su məhsullarında gigiyenik şərtlərin yerinə yetirildiyini göstərən və demək olar ki, bütün dünyada etibarlı olan sertifikatdır. 1944-cü ildə qurulan NSF (Milli Sanitariya/Gigiyena Fondu) qlobal miqyasda fəaliyyət göstərir və onun ən mühüm məqsədi ictimai sağlamlığı qorumaqdır.)</p>
  </div>
  </div>
  <div className='h-[134p] rounded-lg border border-[#405d72]/20'>
  <div className='mt-4 mb-4 mr-3 ml-3'>
<p className='text-[#6f9b0b] text-lg font-normal mb-3'>35</p>
<p className='text-black text-sm font-normal'>Ekoloji əkinçilik üçün uyğunluğu ECOCERT sertifikatı ilə təsdiq edilmişdir. (ECOCERT 1991-ci ildə Fransada yaradılmış orqanik sertifikatlaşdırma təşkilatıdır. Əsası Avropada olsa da, 80-dən çox ölkədə yoxlamalar aparır və onu dünyanın ən böyük orqanik sertifikatlaşdırma təşkilatlarından birinə çevirir. Onların əsas prinsipi “ekoloji cəhətdən təmiz” məhsul uyğunluğunu təmin etməkdir.)</p>
  </div>
  </div>

  </div>
</div>

    </div>
    </div>
  </div></>
  )
}

export default Products