import React from 'react';
import { blogLeft, blogRightBottom, blogRightTop, leaf, poster, services1, services2, services3 } from '../../../assets/images';

const Services = () => {
  return (
    <div className='mt-[160px]'>
      <div className='max-w-[1260px] flex mx-auto justify-between '>
      <div className=' text-center'>
</div>
      <div className='text-center flex flex-col justify-end ml-20'>
      <p className='text-[20px] italic text-[#BCBCBC]'>Xidmətlər</p>
      <p className='text-[#6F9B0B] text-[32px] font-medium'>İstifadə sahəsi</p>
</div>
<div>
  <img className='h-[203px] w-[154px]' src={leaf}></img>
</div>
      </div>
    <div className='max-w-[1260px] h-[416px] mx-auto flex justify-between mt-[100px] mb-[100px]'>
    <div>
   <img className='h-[348px] w-[524px]' src={blogLeft}></img>
    </div> 
    <div className='flex flex-col justify-center w-[696px]'>
      <p className='text-lg'>Parniklərdə, ştilxanalarda, quşçuluq və heyvandarlıqda, açıq və qapalı hovuzlarda, xəstəxanalarda, hamamlarda, suvarma sistemlərində, bütün növ məkanların dezinfeksiyasında bizim məhsulumuzu istifadə edə bilərsiniz. . Hər damlasında möcüzə var. Buna zəmanət veririk.</p>
      <a href='/services'> <p className='text-[#6F9B0B] mt-3'>Ətraflı bax <i class="fa-solid fa-arrow-right"></i></p></a>
    </div>
    </div>
  </div>
  );
};

export default Services;

