import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    createRoutesFromElements,
    Route,
    ScrollRestoration,
  } from "react-router-dom";
  import Header from "./Components/Header/Header";
  import Footer from "./Components/Footer/Footer";
  import Home from "../src/Pages/Home/Home";
  
  import { ToastContainer } from "react-toastify";
  import About from "./Pages/About/About";
  import Contact from "./Pages/Contact/Contact";
  import Products from "./Pages/Products/Products";
  import WhoUs from "./Pages/WhoUs/WhoUs";
  import Services from "./Pages/Services/Services";
  import Blog from "./Pages/Blog/Blog";
import BlogDetail from "./Pages/BlogDetail/BlogDetail";
import { FloatingWhatsApp } from "react-floating-whatsapp";
  
  
  
  const Layout = () => {
    return (
      <div>
         <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        /> 
        <Header />
        <ScrollRestoration />
        <Outlet />
        <Footer />
      </div>
    );
  };
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/" element={<Layout />}>
          {/* ==================== Header Navlink Start here =================== */}
          <Route path="/" element={ <Home />}></Route>
          <Route path="/who-us" element={ <WhoUs/>}></Route>
          <Route path="/products" element={ <Products/>}></Route>
          <Route path="/services" element={ <Services/>}></Route>
          <Route path="/blogs" element={ <Blog/>}></Route>
          <Route path="/blog-detail/:id" element={ <BlogDetail/>}></Route>
          <Route path="/contact" element={ <Contact/>}></Route>
        </Route>
      </Route>
    )
  );
  
  function App() {
  
    return (
      <div className="">
      <div className="font-bodyFont">
      <div>
    <FloatingWhatsApp phoneNumber='+994512714725' accountName="S.A.R Kimya" statusMessage='' chatMessage='Salam, sizə necə kömək edə bilərik?' placeholder='Mesaj yazın...' avatar='./avatar.png' messageDelay='1' />
      <RouterProvider router={router} />
      </div>
    </div>
    </div>
    );
  }
  
  export default App;