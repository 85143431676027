import React from 'react'
import ProductCard from './ProductCard';
import { certificate1, certificate2, certificate3, certificate4, certificate5, certificate6, huwasan, productBg, virus1, virus2, virus3, virus4, virus5, whyus } from '../../../assets/images';


const Products = () => {
  return (
    <div className='bg-[#F1F1F1] h-[482px]'>
    <div className='max-w-[1260px] mx-auto'>
    <div className='mt-[100px] w-[80%] mx-auto pt-10 mb-10'>
  <div>
    <p className='text-center text-[#BCBCBC] text-[20px] italic '>Məhsullarımız</p>
    <img style={{width:'229px', height:'131px', margin: '0 auto', marginBottom:'20px'}} src={huwasan}></img> 
  </div>
  <div>
    <p>
    Aktiv oksigenə əsaslanan və tərkibində kolloid gümüşlə stabilləşdirilmiş Hidrogen Peroksid olan, yüksək səviyyədə dezinfeksiyanı təmin edən, geniş fəaliyyət spektrinə malik, Bakterisid, virusisidal, sporosid, funqisid və amibisid təsirli, stabil, rəngsiz və qoxusuz, heç bir narahatedici rəng, qoxu və ya dad yaratmayan, 99,9% nisbətində təbii parçalana bilən, reaksiyadan sonra suya və oksigenə çevrilən, zəhərli olmayan, ətrafda heç bir iz (qalıq) buraxmayan, aşağı dozalarda yüksək səviyyədə təsir göstərən, təbiət və insanlar üçün faydalı olan ekoloji dezinfeksiya edicidir.
    </p>
    <a href='/products'> <p className='text-[#6F9B0B] mt-3 text-center'>Ətraflı bax <i class="fa-solid fa-arrow-right"></i></p></a>
  </div>
    </div>
    <div className='bg-[white] relative  shadow-lg rounded-xl'>
  <div className='flex justify-between gap-x-20'>
    <div className='w-1/5 flex flex-col justify-around items-center '>
      <img className='w-auto h-[90px]' src={virus1} alt='virus1' />
      <p className='text-center mt-[10px]'>Bakteriyalara, viruslara və funqisidlərə qarşı effektiv</p>
    </div>
    <div className='w-1/5 flex flex-col justify-around items-center'>
      <img  className='w-auto h-[90px]'src={virus2} alt='virus2' />
      <p className='text-center mt-[10px]'>Qoxusuz, rəngsiz və dadsız</p>
    </div>
    <div className='w-1/5 flex flex-col justify-around items-center'>
      <img  className='w-auto h-[90px]' src={virus3} alt='virus3' />
      <p className='text-center mt-[10px]'>Təkmilləşdirilmiş su gigiyenası</p>
    </div>
    <div className='w-1/5 flex flex-col justify-around items-center'>
      <img  className='w-auto h-[90px]' src={virus4} alt='virus4' />
      <p className='text-center mt-[10px]r'>Bioloji parçalana bilən məhsul</p>
    </div>
    <div className='w-1/5 flex flex-col justify-around items-center'>
      <img  className='w-auto h-[90px]' src={virus5} alt='virus5' />
      <p className='text-center mt-[10px]'>Biofilmlərə qarşı sübut edilmiş effektivlik</p>
    </div>
  </div>
</div>
    </div>
  </div>
  )
}

export default Products