import React from 'react'
import { discount1, discount2, discount3, hero1, hero2, heroBg } from '../../../assets/images';


const Hero = () => {
  return (
   <div style={{ backgroundImage: `url(${heroBg})` }} >
     <div className="max-w-[1260px] mx-auto flex justify-between h-[633px] ">
      <div className='w-1/2 flex flex-col justify-center'> 
      <h1 className='text-[70px] font-extrabold text-[#6F9B0B] tracking-wider'>HUWASAN</h1>
        <p className='text-4xl leading-10'>Təmiz bir gələcək üçün etibarlı dostunuz</p>
        <a href='/about'>
        <button className="w-[148px] h-10 p-2.5 bg-[#6f9b0b] rounded-xl justify-center items-center gap-2.5 inline-flex mt-3">
    <div className="text-white text-base">Daha çox</div>
</button>
        </a>
      </div>
      <div className='w-2/5 flex flex-col justify-end mb-10'><img src={hero2}></img></div>
  </div>
   </div>
  )
} 

export default Hero 