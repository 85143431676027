import React, { useState } from 'react';
import { logo } from '../../assets/images';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const location = useLocation(); // Get current route

  // Function to check if the current path is active
  const isActiveLink = (path) => location.pathname === path;

  return (
    <>
      <div className='bg-[#E7E7E7] h-[80px] pt-5'>
        <div className='max-w-container mx-auto h-[45px] flex justify-between'>
          <div>
            <a href='/'><img alt='logo' height={76} width={167} src={logo}></img></a>
          </div>
          <div className='flex justify-center items-center'>
            <nav>
              <ul className="flex space-x-8">
                <a href="/">
                  <li
                    className={isActiveLink('/') ? 'text-[#6f9b0b] underline underline-offset-4' : ''}
                  >
                    Ana səhifə
                  </li>
                </a>
                <a href="/who-us">
                  <li
                    className={isActiveLink('/who-us') ? 'text-[#6f9b0b] underline underline-offset-4' : ''}
                  >
                    Biz kimik
                  </li>
                </a>
                <a href="/products">
                  <li
                    className={isActiveLink('/products') ? 'text-[#6f9b0b] underline underline-offset-4' : ''}
                  >
                    Məhsullar
                  </li>
                </a>
                <a href="/services">
                  <li
                    className={isActiveLink('/services') ? 'text-[#6f9b0b] underline underline-offset-4' : ''}
                  >
                    Xidmətlər
                  </li>
                </a>
                <a href="/blogs">
                  <li
                    className={isActiveLink('/blogs') ? 'text-[#6f9b0b] underline underline-offset-4' : ''}
                  >
                    Bloq
                  </li>
                </a>
                <a href="/contact">
                  <li
                    className={isActiveLink('/contact') ? 'text-[#6f9b0b] underline underline-offset-4' : ''}
                  >
                    Əlaqə
                  </li>
                </a>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;






























